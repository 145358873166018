import Vue from 'vue';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import goTo from 'vuetify/es5/services/goto';
import '@mdi/font/css/materialdesignicons.css';

import App from './App.vue'
import LandingPage from "./pages/LandingPage.vue";
import Support from "./pages/Support.vue";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    routes: [{
            path: '/',
            name: 'Home',
            component: LandingPage
        },
        {
            path: '/presskit',
            name: 'Press-Kit',
            component: () =>
                import ('./pages/Presskit')
        },
        {
            path: '/mobile-funding',
            name: 'Mobile',
            component: () =>
                import ('./pages/MobileFunding')
        },
        {
            path: '/legal-notice',
            name: 'Legal Notice',
            component: () =>
                import ('./pages/LegalNotice')
        },
        {
            path: '/privacy',
            redirect: { name: 'Privacy Policy' }
        },
        {
            path: '/privacy-policy',
            name: 'Privacy Policy',
            component: () =>
                import ('./pages/PrivacyPolicy')
        },
        {
            path: '/oauth/twitch',
            component: () =>
                import ('./pages/Twitch')
        },
        {
            path: '/oauth/discord',
            component: () =>
                import ('./pages/DiscordImageBot')
        },
        {
            path: '/bug-report',
            redirect: { name: 'Support' }
        },
        {
            path: '/support',
            name: 'Support',
            component: Support
        },
        {
            path: '/support/bug-report',
            name: 'Bug-Report',
            component: () =>
                import ('./pages/BugReport')
        },
        {
            path: '/support/ban-appeal',
            name: 'Ban-Appeal',
            component: () =>
                import ('./pages/BanAppeal')
        },
        {
            path: '/support/1',
            name: '1',
            component: () =>
                import ('./articles/NoPlayers')
        },
        {
            path: '/support/2',
            name: '2',
            component: () =>
                import ('./articles/Mobile')
        },
        {
            path: '/support/3',
            name: '3',
            component: () =>
                import ('./articles/Refund')
        },
        {
            path: '/support/4',
            name: '4',
            component: () =>
                import ('./articles/PremiumContent')
        },
        {
            path: '/support/5',
            name: '5',
            component: () =>
                import ('./articles/PremiumPlayerCount')
        },
        {
            path: '/support/6',
            name: '6',
            component: () =>
                import ('./articles/Steam')
        },
        {
            path: '/support/7',
            name: '7',
            component: () =>
                import ('./articles/Report')
        },
        {
            path: '/support/8',
            name: '8',
            component: () =>
                import ('./articles/VoteKick')
        },
        {
            path: '/support/9',
            name: '9',
            component: () =>
                import ('./articles/Mute')
        },
        {
            path: '/support/10',
            name: '10',
            component: () =>
                import ('./articles/LanguageUnsopported')
        },
        {
            path: '/support/11',
            name: '11',
            component: () =>
                import ('./articles/TranslationError')
        },
        {
            path: '/support/12',
            name: '12',
            component: () =>
                import ('./articles/CreatePackage')
        },
        {
            path: '/support/13',
            name: '13',
            component: () =>
                import ('./articles/PackageUploadError')
        },
        {
            path: '/support/14',
            name: '14',
            component: () =>
                import ('./articles/StreamingIntegration')
        },
        {
            path: '/support/15',
            name: '15',
            component: () =>
                import ('./articles/InviteViewers')
        },
        {
            path: '/support/16',
            name: '16',
            component: () =>
                import ('./articles/DrawingTablet')
        },
        {
            path: '/support/17',
            name: '17',
            component: () =>
                import ('./articles/Crashes')
        },
        {
            path: '/support/18',
            name: '18',
            component: () =>
                import ('./articles/ConnectionProblem')
        },
        {
            path: '/support/19',
            name: '19',
            component: () =>
                import ('./articles/PatchProblem')
        },
        {
            path: '/support/20',
            name: '20',
            component: () =>
                import ('./articles/StartupProblem')
        },
        // catch all redirect
        { path: '*', redirect: '/' }
    ]
})

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')