<template>
  <v-toolbar flat color="transparent" class="header" height="150%">
    <v-spacer></v-spacer>    
    <v-toolbar-items class="toolbar-btn" v-if="!isMobile">
      <v-btn
        class="custom-btn"
        style="font-size: 1.4rem"
        text
        @click="$router.push({ name: 'Home' }).catch(() => {})"
        >Home</v-btn
      >
      <v-btn
        class="custom-btn"
        style="font-size: 1.4rem"
        text
        @click="$router.push({ name: 'Home', hash: '#contact' }).catch(() => {})"        
        >Contact</v-btn
      >
    </v-toolbar-items>
    <router-link :to="{ name: 'Home' }">
      <v-img :src="require('../assets/logos/scribble_it.svg')" class="banner" />
    </router-link>
    <v-toolbar-items class="toolbar-btn" v-if="!isMobile">
      <v-btn
        class="custom-btn"
        style="font-size: 1.4rem"
        text
        @click="$router.push({ name: 'Support'}).catch(() => {})"
        >Support</v-btn
      >
      <v-btn
        class="custom-btn"
        style="font-size: 1.4rem"
        text
        @click="$router.push({ name: 'Press-Kit' }).catch(() => {})"
        >Presskit</v-btn
      >
    </v-toolbar-items>
    <v-spacer></v-spacer>
  </v-toolbar>
</template>

<script>
export default {
  name: "Header",

  data: () => ({
    isMobile: false,
  }),

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      if (window.innerWidth < 801) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>
