<template>
  <div>
    <v-container class="fill-height headContainer" v-if="isMobile">
      <v-row class="burger" align="center" justify="left">
        <v-app-bar-nav-icon @click.stop="showDrawer = !showDrawer"></v-app-bar-nav-icon>
      </v-row>
    </v-container>

    <v-navigation-drawer
      color="transparent"
      dark
      v-model="showDrawer"
      absolute
      temporary
      floating
      width="100%"
      overlay-opacity="0.95"
    >
      <v-list dense>
        <v-list-item>
          <v-list-item-title
            align="left"
            class="pointer-cusor burgerItem burgerClose"
            @click.stop="showDrawer = !showDrawer"
            ><v-icon>mdi-close</v-icon></v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="pointer-cusor burgerItem"
            @click="$router.push({ name: 'Home' }).catch(() => {})"
            >Home</v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="pointer-cusor burgerItem"
            @click="
              $router.push({ name: 'Home', hash: '#game' }).catch(() => {})
            "
            >Game</v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="pointer-cusor burgerItem"
            @click="
              $router.push({ name: 'Home', hash: '#contact' }).catch(() => {})
            "
            >Contact</v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="pointer-cusor burgerItem"
            @click="$router.push({ name: 'Press-Kit' }).catch(() => {})"
            >Presskit</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "BurgerMenu",

  data: () => ({
    isMobile: false,
    showDrawer: false,
  }),

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      if (window.innerWidth < 801) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>
