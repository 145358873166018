<template>
  <v-footer bottom fixed class="cookieFooter" v-if="showCookie">
    <v-card      
      dark
      color="#242422"
      elevation="0"
      height="5vh"
      class="mx-auto cookie-consent"
    >
      This website uses cookies
      <v-btn color="grey darken-3" @click="$router.push({ name: 'Privacy Policy' })"
        >Privacy Policy</v-btn
      >
      <v-btn
        @click="setCookie('accepted')"
        class="cookieAccept"
        color="green darken-2"
        >Accept</v-btn
      >
      <v-btn @click="setCookie('denied')" color="red darken-2">Decline</v-btn>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "CookieCheck",

  data: () => ({
    showCookie: false,
    cookieTable: [],
    cookie: "",
    cookieState: "default",
  }),

  created() {
    this.cookieTable = document.cookie.split(";");

    for (var i = 0; i < this.cookieTable.length; i++) {
      this.cookie = this.cookieTable[i];

      if (this.cookie.trim() == "detach-entertainment=accepted") {
        this.cookieState = true;
      } else if (this.cookie.trim() == "detach-entertainment=denied") {
        this.cookieState = false;
      }
    }

    //if (this.cookieState != "") {
    if (this.cookieState == true) {
      this.showCookie = false;
    } else if (this.cookieState == false) {
      this.showCookie = false;
      this.$ga.disable();
    } else {
      this.showCookie = true;
    }
  },

  methods: {
    setCookie(consent) {
      var date = new Date();
      date.setTime(date.getTime() + 31557600000);
      var expires = "expires=" + date.toUTCString();
      document.cookie =
        "detach-entertainment=" +
        consent +
        ";SameSite=None;" +
        expires +
        ";path=/;Secure";
      this.showCookie = false;

      if(consent == 'denied')
      {
        this.$ga.disable();
      }
    },
  },
};
</script>