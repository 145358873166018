<template>
  <v-row class="social-buttons" justify="center" no-gutters>
    <v-col lg="1" style="align-content: center">
      <a href="https://twitter.com/ScribbleItGame">
        <v-img
          class="pointer-cursor"
          :src="require('../assets/buttons/twitter.svg')"
          contain
          height="4rem"
          aspect-ratio="0.978"
        />
      </a>
    </v-col>
    <v-col lg="1" style="align-content: center">
      <a href="https://discord.gg/ScribbleIt">
        <v-img
          class="pointer-cursor"
          :src="require('../assets/buttons/discord.svg')"
          contain
          height="4rem"
          aspect-ratio="0.978"
        />
      </a>
    </v-col>
    <v-col lg="3" style="align-content: center" v-if="showFunding">
      <router-link :to="{ name: 'Mobile' }">
        <v-img
            class="pointer-cursor"
            :src="require('../assets/logos/funding.jpg')"
            contain
            height="8rem"
            aspect-ratio="4.06"
        />
      </router-link>
    </v-col>
    <v-col lg="1" style="align-content: center">
      <a href="https://store.steampowered.com/app/1088150">
        <v-img
          class="pointer-cursor"
          :src="require('../assets/buttons/steam.svg')"
          contain
          height="4rem"
          aspect-ratio="0.978"
        />
      </a>
    </v-col>
    <v-col lg="1" style="align-content: center">
      <a href="https://www.youtube.com/channel/UCi_TvIZFVzaMOdOQnLM9LDw">
        <v-img
          class="pointer-cursor"
          :src="require('../assets/buttons/youtube.svg')"
          contain
          height="4rem"
          aspect-ratio="0.978"
      /></a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    showFunding: Boolean
  }
};
</script>
