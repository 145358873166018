<template>
  <v-container class="bottomSpacer">
  <footer class="footerBottom">
    <social />
    <v-card class="mx-auto" height="5vh" color="#242422" elevation="0">
      <v-btn
        height="5vh"
        color="#242422"
        elevation="0"
        class="white--text mx-auto"
        @click="$router.push({ name: 'Legal Notice' })"
        >Legal Notice</v-btn
      >
      <v-btn
        height="5vh"
        color="#242422"
        elevation="0"
        class="white--text mx-auto"
        @click="$router.push({ name: 'Privacy Policy' })"
        >Privacy Policy</v-btn
      >
    </v-card>
  </footer>
  </v-container>
</template>

<script>
import social from "../components/SocialButtons.vue";

export default {
  components: {
    social,
  },
};
</script>
